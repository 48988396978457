import * as React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import styled from 'styled-components'
import { ReactComponent as SignifyLogo } from '../../assets/signify-health-alt.svg'
import { ReactComponent as CignaLogo } from '../../assets/cigna-alt.svg'
import EvernorthIcon from '../../assets/evernorth-logo.png'
import { ReactComponent as EducationIcon } from '../../assets/education.svg'
import './Timeline.css'
import Title from '../Title/Title'

const EvernorthLogo = styled.img`
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -14px;
    margin-top: -12px;
`

const JobPointList = styled.ul`
    font-family: Menlo, Monaco, 'Courier New', monospace;
    font-size: 0.75em;
    line-height: 1.3em;
    margin: 2em;
    margin-left: 1em;
    padding: 0;
    list-style-type: none;
`

const JobPoint = styled.li`
    margin: 0;
    margin-bottom: 1em;
    padding-left: 2em;
    position: relative;
    display: flex;
    align-items: center;
    color: rgb(163, 193, 243);

    &:before {
        content: '';
        background: rgb(174, 147, 236);
        vertical-align: middle;
        transform: rotate(45deg);
        height: 0.6em;
        width: 0.6em;
        display: block;
        position: absolute;
        left: 0;
    }
`

const TitleDescription = styled.h4`
    font-family: monospace;
    color: rgb(226, 228, 232);
    margin-top: 0.5em;
`

const DarkVerticalTimelineElement = styled(VerticalTimelineElement)`
    .vertical-timeline-element-icon {
        box-shadow: 0 0 0 4px rgb(255, 255, 255), inset 0 2px 0 rgba(0, 0, 0, 0.08),
            0 3px 0 4px rgba(0, 0, 0, 0.05);
    }
`

const SkillList = styled.div`
    font-family: monospace;
    font-size: 0.85em;
    margin-top: 5px;
    padding: 10px;
    padding-left: 0px;
    display: flex;
    flex-wrap: wrap;
`
const TechSkill = styled.span`
    background-color: rgb(94, 177, 89);
    color: rgb(42, 54, 74);
    padding: 2px;
    margin-top: 5px;
    margin-right: 5px;
`

function Timeline() {
    return (
        <VerticalTimeline lineColor="rgb(174, 147, 236)">
            <DarkVerticalTimelineElement
                contentArrowStyle={{ borderRight: '7px solid rgb(42, 54, 74)' }}
                date="November 2022 - Present"
                dateClassName="date-style"
                contentStyle={{ background: 'rgb(42, 54, 74)' }}
                iconStyle={{ background: 'rgb(255, 255, 255)', color: '#fff' }}
                style={{ boxShadow: 'none' }}
                icon={<EvernorthLogo src={EvernorthIcon} alt="Evernorth" />}
            >
                <Title>Architecture Director</Title>
                <TitleDescription>Enterprise Architecture, Evernorth</TitleDescription>
                <SkillList>
                    <TechSkill>AWS</TechSkill>
                    <TechSkill>Serverless</TechSkill>
                    <TechSkill>Go</TechSkill>
                    <TechSkill>TypeScript / Node</TechSkill>
                    <TechSkill>React</TechSkill>
                    <TechSkill>GraphQL</TechSkill>
                    <TechSkill>Teraform</TechSkill>
                </SkillList>
                <JobPointList>
                    <JobPoint>
                        Lead shared services team focused on designing and building FHIR based API
                        services enabling partner/provider appointment scheduling, patient/provider
                        chat, triage services, lab scheduling, etc.
                    </JobPoint>
                    <JobPoint>
                        Led team to build new Digital Triage functionality designed to diagnose and
                        direct patients to providers and other self care health resources
                    </JobPoint>
                    <JobPoint>
                        Designed AWS based back-end infrastructure to support Digital Triage and
                        other MVP application capabilities
                    </JobPoint>
                    <JobPoint>
                        Built document export service using TypeScript and React to deliver patient
                        triage and lab reports to Epic EHR/EMR
                    </JobPoint>
                </JobPointList>
            </DarkVerticalTimelineElement>
            <DarkVerticalTimelineElement
                contentStyle={{ background: 'rgb(42, 54, 74)' }}
                contentArrowStyle={{ borderRight: '7px solid rgb(42, 54, 74)' }}
                date="2021 - October 2022"
                dateClassName="date-style"
                iconStyle={{ background: 'rgb(255, 255, 255)' }}
                icon={<SignifyLogo />}
            >
                <Title>Sr. Director of Analytics Technology</Title>
                <TitleDescription>Episodes of Care, Signify Health</TitleDescription>
                <SkillList>
                    <TechSkill>AWS</TechSkill>
                    <TechSkill>Serverless</TechSkill>
                    <TechSkill>ECS</TechSkill>
                    <TechSkill>Docker</TechSkill>
                    <TechSkill>Python</TechSkill>
                    <TechSkill>Pandas</TechSkill>
                    <TechSkill>TypeScript</TechSkill>
                    <TechSkill>SQL</TechSkill>
                    <TechSkill>ETL</TechSkill>
                    <TechSkill>GraphQL</TechSkill>
                    <TechSkill>Teraform</TechSkill>
                    <TechSkill>PowerBI</TechSkill>
                    <TechSkill>Linux</TechSkill>
                </SkillList>
                <JobPointList>
                    <JobPoint>
                        Led initiatives focused on building new web-based platforms aimed at
                        providing better self-service capabilities to support BPCI-A and commercial
                        episodes program reporting
                    </JobPoint>
                    <JobPoint>
                        Worked cross-functionally with stakeholders to propose, research and design
                        a new scalable analytics platform MVP
                    </JobPoint>
                    <JobPoint>
                        Reduced data turnaround time from days to hours by proposing and
                        implementing PowerBI reporting; built claims ETL process, and provided
                        training to client analytics
                    </JobPoint>
                    <JobPoint>
                        Hired and built engineering team to support new analytics and reporting
                        initiatives
                    </JobPoint>
                </JobPointList>
            </DarkVerticalTimelineElement>
            <DarkVerticalTimelineElement
                contentArrowStyle={{ borderRight: '7px solid rgb(42, 54, 74)' }}
                date="2017 - 2021"
                dateClassName="date-style"
                contentStyle={{ background: 'rgb(42, 54, 74)' }}
                iconStyle={{ background: 'rgb(255, 255, 255)', color: '#fff' }}
                style={{ boxShadow: 'none' }}
                icon={<EvernorthLogo src={EvernorthIcon} alt="Evernorth" />}
            >
                <Title>Sr. Software Engineering Manager</Title>
                <TitleDescription>
                    Global Data and Analytics Engineering, Evernorth
                </TitleDescription>
                <SkillList>
                    <TechSkill>AWS</TechSkill>
                    <TechSkill>Serverless</TechSkill>
                    <TechSkill>Docker</TechSkill>
                    <TechSkill>Go</TechSkill>
                    <TechSkill>Python</TechSkill>
                    <TechSkill>TypeScript</TechSkill>
                    <TechSkill>SQL</TechSkill>
                    <TechSkill>NoSQL</TechSkill>
                    <TechSkill>GraphQL</TechSkill>
                    <TechSkill>Teraform/Cloudformation</TechSkill>
                    <TechSkill>Tableau</TechSkill>
                    <TechSkill>Hadoop</TechSkill>
                    <TechSkill>Hive/Impala</TechSkill>
                    <TechSkill>ETL</TechSkill>
                    <TechSkill>Linux</TechSkill>
                </SkillList>
                <JobPointList>
                    <JobPoint>
                        Led Affordability Insights engineering organization, consisting of four
                        engineering teams (4 tech leads; &gt;20 engineers) responsible for the
                        design and implementation of affordability management applications focused
                        on the areas of Competitive Intelligence, Healthcare Cost Trend Analytics,
                        and Cost Anomaly Detection; established engineering best practices and
                        culture
                    </JobPoint>
                    <JobPoint>
                        Designed and built contracting Opportunity Anomaly Detection
                        platform/framework to generate more effective cost-savings actions for
                        provider contractors; total projected company savings of $300~ million over
                        several years; $28~ million of estimated savings during first year
                    </JobPoint>
                    <JobPoint>
                        Built, and managed monthly healthcare claims and membership data delivery,
                        powering over fifteen reporting dashboards and driving millions in savings
                    </JobPoint>
                    <JobPoint>
                        Reduced new cost trend report development time from weeks to days, and
                        increased overall performance by proposing, and implementing a single
                        semantic reporting data solution; provided additional self-service reporting
                        capabilities for medical economics group to more quickly access and analyze
                        data
                    </JobPoint>
                    <JobPoint>
                        Designed and developed cloud based (AWS) software solutions that increased
                        Cigna&apos;s (Evernorth) market affordability position by working with
                        experience owners, product owners, UI/UX teams, and internal stakeholders
                        (contractors, analysts, market managers)
                    </JobPoint>
                    <JobPoint>
                        Led data initiative to modernize, and consolidate data onto cloud based
                        Snowflake platform
                    </JobPoint>
                </JobPointList>
            </DarkVerticalTimelineElement>
            <DarkVerticalTimelineElement
                contentArrowStyle={{ borderRight: '7px solid rgb(42, 54, 74)' }}
                date="2011 - 2017"
                dateClassName="date-style"
                contentStyle={{ background: 'rgb(42, 54, 74)' }}
                iconStyle={{ background: 'rgb(255, 255, 255)', color: '#fff' }}
                style={{ boxShadow: 'none' }}
                icon={<CignaLogo />}
            >
                <Title>Sr. Software Engineer</Title>
                <TitleDescription>Global Data and Analytics Engineering, CIGNA</TitleDescription>
                <SkillList>
                    <TechSkill>Java</TechSkill>
                    <TechSkill>SAS</TechSkill>
                    <TechSkill>SQL</TechSkill>
                    <TechSkill>ETL</TechSkill>
                    <TechSkill>Teradata</TechSkill>
                    <TechSkill>Oracle</TechSkill>
                    <TechSkill>DB2</TechSkill>
                    <TechSkill>AIX</TechSkill>
                </SkillList>
                <JobPointList>
                    <JobPoint>
                        Proposed, designed, and implemented architecture and reporting framework for
                        Volume to Value reporting; platform used to generate hundreds of reports on
                        a monthly basis; designed modular/configurable framework to enable faster
                        and easier future report development
                    </JobPoint>
                    <JobPoint>
                        Identified, and solved reporting performance issues by designing and
                        implementing solution to serve Tableau dashboards that decreased average
                        page load times by as much as 50%; developed service layer using stored
                        procedures in both Teradata and IBM DB2
                    </JobPoint>
                    <JobPoint>
                        Proposed and implemented solution to provide more configurable, and
                        appealing reports to clients; built custom graphing framework in Java to
                        provide new functionality without requiring invasive/costly changes to
                        existing reporting framework
                    </JobPoint>
                    <JobPoint>
                        Created custom incentives reporting application to generate variable period
                        reports used by Cigna clientele to directly pay customer health incentives;
                        developed using SAS, Java, Oracle/IBM DB2 (datasources), and ConnectDirect
                        for automated upload to client portal
                    </JobPoint>
                    <JobPoint>
                        Developed algorithm in SAS for calculating disparate member identification
                        and engagement cycles used to derive more accurate reporting metrics and
                        customer incentives
                    </JobPoint>
                    <JobPoint>
                        Tuned, and managed SQL based ETL jobs across multiple database systems,
                        working with tables and datasets containing billions of medical claims and
                        membership records
                    </JobPoint>
                </JobPointList>
            </DarkVerticalTimelineElement>
            <DarkVerticalTimelineElement
                contentStyle={{ background: 'rgb(42, 54, 74)' }}
                contentArrowStyle={{ borderRight: '7px solid rgb(42, 54, 74)' }}
                date="2011"
                dateClassName="date-style"
                icon={<EducationIcon />}
                iconStyle={{ background: 'rgb(255, 255, 255)' }}
            >
                <Title>University Of Tennessee, Chattanooga</Title>
                <TitleDescription>B.S. in Business Administration</TitleDescription>
                <TitleDescription>Graduated Summa Cum Laude</TitleDescription>
            </DarkVerticalTimelineElement>
        </VerticalTimeline>
    )
}

export default Timeline
