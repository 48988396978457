import * as React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import styled from 'styled-components'
import HeadshotImage from '../../assets/anders-headshot.png'
import { ReactComponent as PdfIcon } from '../../assets/pdf-icon.svg'
import Title from '../Title/Title'

const HeaderWrapper = styled.div`
    width: 100%;
    background: rgb(37, 41, 46);

    @media only screen and (min-width: 1170px) {
        .vertical-timeline.vertical-timeline--two-columns {
            width: 100%;
        }
    }
`

const PdfDownloadIcon = styled(PdfIcon)`
    width: 25px;
    height: 25px;
    margin: 0.25em;
`

const DownloadButton = styled.div`
    border: 1px dashed rgb(174, 147, 236);
    padding: 0.5em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;

    &:hover {
        border-style: solid;
        background: rgb(42, 54, 74);
    }

    span {
        margin: 0.25em;
        margin-top: 0.5em;
        font-size: 0.7em;
    }
`

const NoStyleLink = styled.a`
    text-decoration: none;
`

interface ButtonLinkProps {
    url: string
    buttonText: string
    icon: React.ReactNode
}

function ButtonLink({ url, buttonText, icon }: ButtonLinkProps) {
    return (
        <NoStyleLink href={url} download>
            <DownloadButton>
                {icon} <span>{buttonText}</span>
            </DownloadButton>
        </NoStyleLink>
    )
}

const HeaderIcon = styled.img`
    width: 40px;
    height: 40px;
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
`

const AboutMe = styled(VerticalTimelineElement)`
    .vertical-timeline-element-content {
        background: rgb(32, 36, 40);
        margin-bottom: -14em;
    }

    @media only screen and (min-width: 1170px) {
        .vertical-timeline-element-icon {
            width: 180px;
            height: 180px;
            left: 50%;
            top: 5%;
            margin-left: -90px;
            margin-top: -90x;

            img {
                width: 180px;
                height: 180px;
                margin-left: -90px;
                margin-top: -90px;
            }
        }
    }

    @media only screen and (min-width: 1170px) {
        .vertical-timeline-element-content {
            top: 5em;
            right: 4em;
        }
    }
`

const ContactInfo = styled(VerticalTimelineElement)`
    @media only screen and (min-width: 1170px) {
        .vertical-timeline-element-content {
            top: -4em;
            left: 4em;
        }
    }

    .vertical-timeline-element-content {
        background: rgb(32, 36, 40);
    }

    .vertical-timeline-element-icon {
        box-shadow: unset;
    }
`
const HeaderText = styled.div`
    font-family: Menlo, Monaco, 'Courier New', monospace;
    font-size: 0.75em;
    line-height: 1.3em;
    margin-top: 1.3em;
    margin-left: 1em;
    padding: 0;
    color: rgb(226, 228, 232);
`

const MailLink = styled.a`
    color: rgb(174, 147, 236);
`

function Header() {
    return (
        <HeaderWrapper>
            <VerticalTimeline lineColor="rgb(174, 147, 236)">
                <AboutMe
                    icon={<HeaderIcon src={HeadshotImage} alt="Anders Ravenholt" />}
                    contentArrowStyle={{ borderRight: '7px solid rgb(32, 36, 40)' }}
                >
                    <Title>About Me</Title>
                    <HeaderText>
                        Hello! My name is Anders. I&apos;m an experienced leader, and full stack
                        software engineer with over ten years of health care industry knowledge. I
                        have a deep technical understanding that extends across multiple technical
                        disciplines, including data analytics, data management, and web application
                        development. I have built and lead engineering teams and organizations, and
                        have extensive experience working with cross-functional groups to craft
                        cost-effective, user-focused software solutions.
                    </HeaderText>
                    <HeaderText>
                        I&apos;m a geek at heart, and in my spare time I enjoy learning and
                        tinkering with anything technology or engineering related. It&apos;s not
                        uncommon to find me trying to learn a new language (currently on the Rust
                        fad), or playing with my 3D printer and Raspberry PI.
                    </HeaderText>
                </AboutMe>
                <ContactInfo contentArrowStyle={{ borderRight: '7px solid rgb(32, 36, 40)' }}>
                    <Title>Contact Information</Title>
                    <HeaderText>Name: Anders Ravenholt</HeaderText>
                    <HeaderText>
                        Email:{' '}
                        <MailLink target="_blank" href="mailto:hireanders@proton.me">
                            hireanders@proton.me
                        </MailLink>
                    </HeaderText>
                    <HeaderText>Phone: (925) 272-8364 (Google Voice Number)</HeaderText>
                    <HeaderText>Location: Chattanooga, TN</HeaderText>
                    <HeaderText />
                    <hr />
                    <HeaderText>
                        <ButtonLink
                            url="Anders-Ravenholt-Resume.pdf"
                            buttonText="Download Resume"
                            icon={<PdfDownloadIcon />}
                        />
                    </HeaderText>
                </ContactInfo>
            </VerticalTimeline>
        </HeaderWrapper>
    )
}

export default Header
