import React from 'react'
import './App.css'
import styled from 'styled-components'
import Timeline from './components/Timeline'
import Header from './components/Header'

const AppWrapper = styled.section`
    background: rgb(30, 30, 30);
`

function App() {
    return (
        <AppWrapper>
            <Header />
            <Timeline />
        </AppWrapper>
    )
}

export default App
